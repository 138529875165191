body{
  background-color: $white;
}
.th-solutions-header{
  width: 88%;
  font-family: $font-family-light;
  margin: $margin-auto;
  background-color: $primary-brand-theme;
  .th-title-solutions{
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: $padding-xlarge + 10;
    h1{
      display: block;
      width: 100%;
      font-size: 2em;
      color: $white;
    }
    span{
      color: $white;
    }
    .th-content-icons{
      margin: $margin-auto;
      margin-bottom: $margin-base;
      .th-icon-container-money{
        border-radius: 10px;
        background-color: $white;
        margin: $margin-auto;
        width: 70px;
        height: 70px;
        i{
          font-size: $font-large + 10;
          color: #2e6089;
          top:22%;
          position: relative;
          &.icon-cr-car-monopoly{
            font-size: 60px;
            top:10%;
          }
        }
      }
    }
  }
  .th-image-container{
    background-color: $white;
    position: relative;
    .th-half-image{
      background-color: $primary-brand-theme;
      position: absolute;
      height: 30%;
      width: $full-width;
      box-shadow: 0 10px 19px rgba(0, 0, 0, 0.07);
    }
    .th-image{
      margin: $margin-auto;
      position: relative;
      top: 0;
      text-align: center;
      img{
        width: 70%;
        padding-left: $padding-large * 5;
        padding-right: $padding-large * 5;
        padding-top: $padding-large;
        padding-bottom: $padding-large;
        box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.5);
        background-color: $white;
      }
    }
    .th-content{
      margin: $margin-auto $margin-base;
      position: relative;
      padding-top: $padding-large * 3;
      top: 0;
      text-align: center;
      box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.5);
      background-color: $white;
      width: 85%;
      .th-content-row{
        display: flex;
        margin-bottom: 15%;
          &.th-car-customization{
            margin-bottom: 15%;
          }
        &.th-bill-financing{
          .th-content-simply-list{
            margin-left: 4%;
          }
        }

        .th-content-simply-list{
          width: 60%;
          margin-left: 10%;
          margin-right: 0;
          position: relative;
          .th-content-simply-list-title{
            text-align: left;
            margin-bottom: $margin-large;
            &.th-text-big{
              span{
                font-size: $font-large;
              }
            }
            span{
              font-weight: $font-weight-bold;
            }

          }
          ul{
            display: block;
            list-style: inherit;
            width: 90%;
            li{
              text-align: left;
              margin-bottom: $margin-small * 2;
            }
          }
        }
        .th-img-solutions-container{
          img {
            height: 220px;
            width: auto;
          }
        }
        .th-img-flexibility-container{
          img {
            height: 300px;
            width: auto;
          }
        }
        .th-img-volumen-processing-container{
          img {
            height: 250px;
            width: auto;
          }
        }
        .th-img-process-customatization-container{
          img {
            position: relative;
            top: -10px;
            height: 350px;
            width: auto;
          }
        }
      }
    }
  }

 }
.th-solutions-content{
  position: relative;
  text-align: center;
  padding: $padding-large;
  width: 80%;
  margin:$margin-auto;
  margin-top: 3%;
  background-color: $white;
  h3{
    color: #404040;
    &.th-h3-title{
      font-weight: bold;
      color: #404040;
      padding-top: $padding-large;
      font-size: $font-xlarge;
    }

  }
  &.th-case-study{
    h3 {
      margin-bottom: $margin-base * 2;
    }
    .th-solutions-large-text{
      padding-top: 2%;
      width: 75%;
      display: block;
      margin: $margin-auto;
      margin-bottom: $margin-base * 3;
    }
    img {
      width: 250px;
      height: auto;
    }
  }

  &.th-solutions-footer{
    width: 100%;
    padding-left: $remove-padding;
    padding-right: $remove-padding;
  }
  .th-content-simply-list{
    width: 50%;
    margin-left: 15%;
    position: relative;
    .th-content-simply-list-title{
      text-align: left;
      margin-bottom: $margin-large;
      &.th-text-big{
        span{
          font-size: $font-large;
        }
      }
      span{
        font-weight: $font-weight-bold;
      }

    }
    ul{
      display: block;
      list-style: inherit;
      li{
        text-align: left;
        margin-bottom: $margin-small;
      }
    }
  }
  .th-content-icons{
    position: relative;
    top: 15px;
    ul{
      margin-top: $margin-large;
      margin-bottom: $margin-large;
      padding-left: $remove-padding;
      li{
        margin-right: $margin-base;
        .th-icon-container-technology-app{
          border-radius: 10px;
          background-color: $black;
          margin: $margin-auto;
          width: 70px;
          height: 70px;
          i{
            font-size: $font-large + 10;
            color: $white;
            vertical-align: middle;
            top:22%;
            position: relative;
            &.icon-cr-clients-infinity{
              font-size: $font-xlarge + 20;
              position: relative;
              top: 9px;
            }
          }
        }
      }
    }
  }
  &.th-solutions-core-loan-backend-container{
    display: flex;
    top: 100px;
    .th-content-simply-list{
      width: 38%;
    }
    .th-img-core-loan-container{
      width: 40%;
      img{
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-solutions-collections-management-container{
    display: flex;
    .th-content-simply-list{
      width: 55%;
    }
    .th-img-collections-management-container{
      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }
  &.th-solutions-risk-management-container{
    display: flex;
    background-color: #2e6089;
    padding-top: 5%;
    padding-bottom: 3%;
    .th-content-simply-list{
      margin-left: 5%;
      margin-right: 1%;
      width: 55%;
      .th-content-simply-list-title{
        span{
          color: $white;
        }
      }
      ul{
        display: block;
        list-style: none;
        width: 90%;
        li{
          text-align: left;
          margin-bottom: $margin-large;
          span{
            color: $white;
          }
          &::before {
            content: "• ";
            color: $white;
          }
        }
      }
    }
    .th-img-risk-management-container{
      display: flex;
      position: relative;
      top:20px;
      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }
  &.th-solutions-api-container{
    display: flex;
    top: 100px;
    .th-content-simply-list{
      margin-left: 5%;
      ul{
        width: 85%;
      }
    }
    .th-img-api-container{
      width: 40%;
      margin-left: 15%;
      img{
        width: 100%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-solutions-marketing-tracking-container{
    display: flex;
    top: 50px;
    .th-content-simply-list{
      margin-left: 0;
      ul{
        width: 85%;
      }
    }
    .th-img-marketing-tracking-container{
      width: 30%;
      margin-left: 15%;
      img{
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-solutions-machine-learning-container{
    margin-top: $margin-base * 3;
    padding-top: $padding-large * 3;
    padding-bottom: $padding-large * 2;
    background-color: $gray-light-theme;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.06);
    .th-content-simply-list{
      .th-content-simply-list-title{
        font-size: $font-xlarge;
        position: relative;
        top:20px;
      }
      ul{
        position: relative;
        top:20px;
        li{
          margin-bottom: 20px;
        }
      }
    }

  }
  &.th-solutions-solid-credit-process-container{
    background-color: $primary-brand-theme;
    margin: 0 auto;
    padding-top: $remove-padding;
    padding-bottom: $remove-padding;
    height: 410px;
    .th-solid-credit-process-image{
      width: 45%;
      margin: 0 auto;
      right: 0;
      i{
        margin-left: 30%;
        font-size: 400px;
        color: $white;
      }
    }
    .th-solid-credit-process-list{
      width: 40%;
      margin-left: 0%;
      position: relative;
      top: 80px;
      .th-content-simply-list-title{
        text-align: left;
        margin-bottom: $margin-large;
        span{
          font-weight: $font-weight-bold;
          color: $white;
          font-size: $font-xlarge;
        }

      }
      ul{
        display: block;
        list-style: none;
        li{
          text-align: left;
          margin-bottom: $margin-large;
          span{
            color: $white;
          }
          &::before {
            content: "• ";
            color: $white;
          }
        }
      }

    }
  }
  .th-content-machine-learning-container{
    margin-left: 5%;
    img {
      height: 240px;
      width: auto;
    }
  }
  .th-footer-container{
    background-color: $black;
    color: $white;
    display: flex;
    padding-bottom: $padding-large;
    .th-footer-column{
      width: 30%;
      margin-right: 3%;
      padding-left: 1%;
      padding-right: 1%;
      &:nth-last-child(1){
        margin-right: 0;
      }
        .th-footer-row{
          .th-title-footer-row{
            text-align: left;
          }
          hr{
            border-color: rgba(255, 255, 255, .3);
          }
          .th-content-footer-row{
            text-align: left;
            ul{
            &.th-menu-footer{
              display: block;
              padding-left: 0;
              text-align: left;
              li{
                margin-bottom: $margin-base;
              }
            }
          }
            input {
              width: 85%;
            }
          }
          section {
            margin-top: $margin-base;
            text-align: left;
            button{
              background-color: $black;
              border: 1px solid $white;
              padding: 0.30rem 1.5rem;
              font-size: $font-small;
              display: inline;
            }
          }
        }
    }
  }
  .list-unstyled{
    display: block;
  }
}

@media (min-width: 100px) and (max-width: 600px){
  .th-solutions-header{
    width: 100%;
    .th-image-container{
      .th-content{
        width: 100%;
        padding-top: 0;
        .th-content-row{
          display: block;
          padding-top: 20px;
          border-bottom: 1px solid #d7d7d7;
          .th-content-simply-list{
            width: 100%;
            margin-left: 0;
            .th-content-simply-list-title{
              &.th-text-big{
                margin-left: 15px;
                span{
                  font-size: 1.5rem;
                }
              }
            }
            ul{
              padding-left: $remove-padding;
              margin-left: 10%;
              width: 85%;
            }
          }
          .th-img-solutions-container{
            img{
              width: 90%;
              height: auto;
              margin-left: 5%;
            }
          }
          .th-img-process-customatization-container{
            margin-top: 30px;
            img{
              width: 90%;
              height: auto;
              margin-left: 10%;
            }
          }
          .th-img-volumen-processing-container{
            img{
              width: 100%;
              height: auto;
            }
          }
          .th-img-flexibility-container{
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
  .th-solutions-content{
    &.th-case-study{
      margin: 0;
      padding: 0;
      width: 100%;
      h3{
        &.th-h3-title{
          font-size: 1.5rem;
        }
      }
      span{
        width: 90%!important;
      }
    }
  }
}



