body{
  background-color: $white;
}
.th-home-header{
  width: 88%;
  font-family: $font-family-light;
  margin: $margin-auto;
  background-color: $primary-brand-theme;
  .th-arrows{
    width: 90%;
    margin: $margin-auto;
    display: flex;
    text-align: center;
    h4{
      color: $white;
      font-size: $font-large - 4;
      font-weight: bold;
      text-transform: uppercase;
    }
    span{
      color: $white;
    }
    i{
      color: #2eb5ee;
      font-size: 100px;
      position: relative;
      top: 25px;
      margin-left: $margin-base;
      margin-right: $margin-base;
    }
  }
  .th-video-container{
    background-color: $white;
    position: relative;
    .th-half-video{
      background-color: $primary-brand-theme;
      position: absolute;
      height: 50%;
      width: $full-width;
      box-shadow: 0 10px 19px rgba(0, 0, 0, 0.07);
    }
    .th-video{
      margin: $margin-auto;
      position: relative;
      top: 0;
      text-align: center;
      video{
        width: 80%;
        box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.2);
      }
  }
  }
 }
.th-home-content{
  position: relative;
  text-align: center;
  padding: $padding-large;
  width: 96%;
  margin: $margin-auto;
  h3{
    color: #404040;

  }
  &.th-home-footer{
    width: 100%;
    padding-left: $remove-padding;
    padding-right: $remove-padding;
  }
  .th-context-layers{
    width: 30%;
    margin-left: 10%;
    margin-right: 60px;
    position: relative;
    top: 40px;
    ul{
      display: block;
      list-style: none;
      text-align: right;
      li{
        margin-bottom: $margin-xlarge * 2;
        .th-title-context-layers{
          h4{
            font-weight: bold;
            font-family: $font-family-base;
            font-size: $font-base;
            .th-uppercase{
              text-transform: $font-uppercase;
            }
          }
        }
        .th-description-context-layers{
          line-height: 1.6em;
        }
      }
    }
  }
  .th-graphic-context-layers{
    width: 50%;
    img{
      width: 100%;
      height: auto;
    }
  }
  .th-home-our-solutions{
    width: $full-width;
    margin: ($margin-large * 3) $margin-auto;
    background-color: $primary-brand-theme;
    padding-top: $padding-large * 3;
    padding-bottom: $padding-large * 3;
    .th-home-our-solutions-title{
      padding-top: $padding-large;
      padding-bottom: $padding-large * 2;
      h2{
        color: $white;
        font-family: $font-family-base;
      }
    }
    .th-home-our-solutions-content{
      width: 50%;
      margin: 0 auto;
      ul{
        margin-top: $margin-large;
        margin-bottom: $margin-large;
        padding-left: $remove-padding;
        li{
          width: 33%;
          .th-icon-container-our-solutions{
            border-radius: 10px;
            background-color: $white;
            margin: $margin-auto;
            width: 50px;
            height: 50px;
            i{
              font-size: $font-large ;
              color: $primary-brand-theme;
              vertical-align: middle;
              top:22%;
              position: relative;
            }
          }
          .th-description-container-our-solutions{
            text-align: center;
            padding: $padding-base 0;
            span{
              color: $white;
              font-weight: bold;
            }
          }
          .th-icon-call-to-action-our-solutions{
            border-radius: 50%;
            height: 30px;
            width: 30px;
            border: 1px solid $white;
            margin: 0 auto;
            i{
              position: relative;
              top: 24%;
              color: $white;
            }
          }
          &:hover{
            cursor: pointer;
            .th-icon-container-our-solutions{
              transition: transform .5s;
              transform: translate(0px, -$margin-base/2);

            }
            .th-icon-call-to-action-our-solutions{
              background-color: $white;
              -webkit-transition: background-color .5s ease-out;
              -moz-transition: background-color .5s ease-out;
              -o-transition: background-color .5s ease-out;
              transition: background-color .5s ease-out;
              i{
                color: $primary-brand-theme;
              }
            }
          }
        }
      }
    }
  }
  .th-mobile-ops-container{
    width: 65%;
    margin: 0 auto;
    padding-top: $padding-base * 4;
    .th-mobile-ops-img-container{
      width: 35%;
      margin: 0 auto;
      right: 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .th-mobile-ops-claim{
      margin-top: 20%;
      text-align: center;
      width: 60%;
      h2{
        font-family: $font-base;
        font-weight: bold;
      }
      h3{
        font-size: $font-small + 5;
      }
      section{
        margin-top: $margin-large;
      }
    }
  }
  .th-technology-app-container{
    width: $half-width;
    margin: $margin-auto;
    padding-top: $padding-large * 3;
    padding-bottom: $padding-large;
    ul{
      margin-top: $margin-large;
      margin-bottom: $margin-large;
      padding-left: $remove-padding;
      li{
        width: 33%;
        .th-icon-container-technology-app{
          border-radius: 10px;
          background-color: $black;
          margin: $margin-auto;
          width: 70px;
          height: 70px;
          i{
            font-size: $font-large ;
            color: $white;
            vertical-align: middle;
            top:30%;
            position: relative;
          }
        }
        .th-description-container-technology-app{
          text-align: center;
          padding: $padding-base 0;
          span{
            color: #404040;
            font-weight: bold;
          }
        }
      }
    }
  }
  .th-footer-container{
    background-color: $black;
    color: $white;
    display: flex;
    padding-bottom: $padding-large;
    .th-footer-column{
      width: 30%;
      margin-right: 3%;
      padding-left: 1%;
      padding-right: 1%;

        .th-footer-row{
          .th-title-footer-row{
            text-align: left;
          }
          hr{
            border-color: rgba(255, 255, 255, .3);
          }
          .th-content-footer-row{
            text-align: left;
            ul{
            &.th-menu-footer{
              display: block;
              padding-left: 0;
              text-align: left;
              li{
                margin-bottom: $margin-base;
              }
            }
          }
            input {
              width: 85%;
            }
          }
          section {
            margin-top: $margin-base;
            text-align: left;
            button{
              background-color: $black;
              border: 1px solid $white;
              padding: 0.30rem 1.5rem;
              font-size: $font-small;
              display: inline;
            }
          }
        }
    }
  }
  .list-unstyled{
    display: block;
  }
}


@media (min-width: 100px) and (max-width: 600px){
  .th-home-header{
    width: 100%;
    .th-text-slider{
      span{
        font-size: 1.5rem;
      }
    }
    .th-arrows{
      //display: block;
      margin-top: 0;
      margin-bottom: 30px;
      .col-lg-3{
        padding-left: 0;
        h4{
          font-size: 1.1rem;
        }
        span{
          font-size: .8rem;
        }
      }
    }
    .th-video-container{
      .th-video{
        video{
          width: 100%;
        }
      }
    }
  }
  .th-home-content{
    padding: $remove-padding;
    width: 100%;
    .th-context-layers{
      width: 90%;
      top: 0;
      margin: $remove-margin;
      ul{
        text-align: left;
        padding-left: 10%;
        li{
          margin-bottom: 10px;
        }
      }
    }
    .th-graphic-context-layers{
      display: none;
    }
    .th-home-our-solutions{
      margin-top:  10px;
      padding: 0 0 15px 0;
      .th-home-our-solutions-title{
        padding-top: 10px;
        padding-bottom: 0;
        h2{
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .th-home-our-solutions-content{
        width: 100%;
        ul{
          li{
            .th-description-container-our-solutions{
              span{
                font-weight: 300!important;
                font-size: .8rem;
              }
          }
          }
        }
      }
    }
    .th-mobile-ops-container{
      width: 100%;
      .th-mobile-ops-claim{
        margin-top: 50px;
        width: 65%;
        h2{
          margin: 0;
          font-size: 1.3rem;
        }
        h3{
          font-size: .9rem;
        }
        section{
          button{
            font-size: 1rem;
          }
        }
      }
    }
    .th-technology-app-container{
      width: 95%;
      padding-top: 0;
      padding-bottom: 0;
      ul{
        li{
          .th-description-container-technology-app{
            span{
              font-size: .8rem;
            }
          }
        }
      }
    }
  }
}


