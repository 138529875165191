

$blue-theme:                      #2e6089 !default;
$blue-light-theme:                #e3ecf6 !default;


$gray-theme:                      #636363!default;
$gray-light-theme:                #fafafa!default;


$primary-brand-theme:       $blue-theme !default;
$secondary-brand:           $green !default;
$links-color:               $orange !default;
$links-color-light:         $orange-light !default;


$th-font-family-base:  'Walsheimlight';
$th-font-family-base-ultra:  'WalsheimUltraLight';
$th-font-family-regular:  'WalsheimRegular';
$th-font-family-bold:  'WalsheimBold';