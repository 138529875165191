body{
  background-color: $white;
}
.th-platform-header{
  width: 88%;
  font-family: $font-family-light;
  margin: $margin-auto;
  background-color: $primary-brand-theme;
  .th-title-platform{
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
    position: relative;
    height: 50px;
    padding-top: $padding-xlarge + 10;
    h1{
      position: absolute;
      display: block;
      width: 100%;
      font-size: 2em;
      color: $white;
    }
  }
  .th-image-container{
    background-color: $white;
    position: relative;
    .th-half-image{
      background-color: $primary-brand-theme;
      position: absolute;
      height: 60%;
      width: $full-width;
      box-shadow: 0 10px 19px rgba(0, 0, 0, 0.07);
    }
    .th-image{
      margin: $margin-auto;
      position: relative;
      top: 0;
      text-align: center;
      img{
        width: 70%;
        padding-left: $padding-large * 5;
        padding-right: $padding-large * 5;
        padding-top: $padding-large;
        padding-bottom: $padding-large;
        box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.5);
        background-color: $white;
      }
    }
  }
 }
.th-platform-content{
  position: relative;
  text-align: center;
  padding: 60px $padding-large;
  width: 94%;
  margin: $margin-auto;
  background-color: $white;
  h3{
    color: $gray-theme;
    &.th-h3-title{
      font-weight: bold;
      color: #404040;
      padding-top: $padding-large;
      font-size: $font-xlarge;
    }

  }
  &.th-platform-footer{
    width: 100%;
    padding-left: $remove-padding;
    padding-right: $remove-padding;
  }
  .th-content-simply-list{
    width: 50%;
    margin-left: 15%;
    position: relative;
    .th-content-simply-list-title{
      text-align: left;
      margin-bottom: $margin-large;
      &.th-text-big{
        span{
          font-size: $font-large;
        }
      }
      span{
        font-weight: $font-weight-bold;
        line-height: 35px;
        font-size: 36px;
      }

    }
    ul{
      display: block;
      list-style: inherit;
      li{
        text-align: left;
        margin-bottom: $margin-small;
      }
    }
  }
  .th-content-icons{
    position: relative;
    top: 15px;
    ul{
      margin-top: $margin-large;
      margin-bottom: $margin-large;
      padding-left: $remove-padding;
      li{
        margin-right: $margin-base;
        .th-icon-container-technology-app{
          border-radius: 10px;
          background-color: $black;
          margin: $margin-auto;
          width: 70px;
          height: 70px;
          i{
            font-size: $font-large + 10;
            color: $white;
            vertical-align: middle;
            top:22%;
            position: relative;
            &.icon-cr-clients-infinity{
              font-size: $font-xlarge + 20;
              position: relative;
              top: 9px;
            }
          }
        }
      }
    }
  }
  &.th-platform-core-loan-backend-container{
    display: flex;
    top: 100px;
    .th-content-simply-list{
      width: 38%;
    }
    .th-img-core-loan-container{
      width: 40%;
      img{
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-platform-collections-management-container{
    display: flex;
    .th-content-simply-list{
      width: 55%;
    }
    .th-img-collections-management-container{
      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }
  &.th-platform-risk-management-container{
    display: flex;
    background-color: #2e6089;
    padding-top: 5%;
    padding-bottom: 3%;
    .th-content-simply-list{
      margin-left: 5%;
      margin-right: 1%;
      width: 55%;
      .th-content-simply-list-title{
        span{
          color: $white;
        }
      }
      ul{
        display: block;
        list-style: none;
        width: 90%;
        li{
          text-align: left;
          margin-bottom: $margin-large;
          span{
            color: $white;
          }
          &::before {
            content: "• ";
            color: $white;
          }
        }
      }
    }
    .th-img-risk-management-container{
      display: flex;
      position: relative;
      top:20px;
      margin-right: 15px;
      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }
  &.th-platform-api-container{
    display: flex;
    top: 80px;
    .th-content-simply-list{
      margin-left: 5%;
      ul{
        width: 85%;
      }
    }
    .th-img-api-container{
      width: 40%;
      margin-left: 15%;
      img{
        width: 100%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-platform-marketing-tracking-container{
    display: flex;
    top: 50px;
    .th-content-simply-list{
      margin-left: 0;
      ul{
        width: 85%;
      }
    }
    .th-img-marketing-tracking-container{
      width: 30%;
      margin-left: 15%;
      img{
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;

      }
    }
  }
  &.th-platform-machine-learning-container{
    margin-top: $margin-base * 3;
    padding-top: $padding-large * 3;
    padding-bottom: $padding-large * 2;
    background-color: $gray-light-theme;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.06);
    .th-content-simply-list{
      .th-content-simply-list-title{
        font-size: $font-xlarge;
        position: relative;
        top:20px;
      }
      ul{
        position: relative;
        top:20px;
        li{

        }
      }
    }
    .th-img-machine-learning-container{
      img {
        height: 350px;
        position: relative;
        top:0px;
      }
    }
  }
  &.th-platform-solid-credit-process-container{
    background-color: $primary-brand-theme;
    margin: 0 auto;
    padding-top: $remove-padding;
    padding-bottom: $remove-padding;
    height: 410px;
    .th-solid-credit-process-image{
      width: 25%;
      margin: 0 auto;
      right: 0;
      margin-left: -7%;
      img{
        width: 100%;
        height: auto;
        margin-top: 30px;
        margin-left: 210px;
      }
    }
    .th-solid-credit-process-list{
      width: 60%;
      margin-left: 16%;
      position: relative;
      top: 80px;
      .th-content-simply-list-title{
        text-align: left;
        margin-bottom: $margin-large;
        span{
          font-weight: $font-weight-bold;
          color: $white;
          font-size: $font-xlarge;
          line-height: 40px;
        }

      }
      ul{
        display: block;
        list-style: none;
        li{
          text-align: left;
          margin-bottom: $margin-base;
          span{
            color: $white;
          }
          &::before {
            content: "• ";
            color: $white;
          }
        }
      }

    }
  }
  .th-content-machine-learning-container{
    margin-left: 5%;
    img {
      height: 240px;
      width: auto;
    }
  }
  .th-footer-container{
    background-color: $black;
    color: $white;
    display: flex;
    padding-bottom: $padding-large;
    .th-footer-column{
      width: 30%;
      margin-right: 3%;
      padding-left: 1%;
      padding-right: 1%;
      &:nth-last-child(1){
        margin-right: 0;
      }
        .th-footer-row{
          .th-title-footer-row{
            text-align: left;
          }
          hr{
            border-color: rgba(255, 255, 255, .3);
          }
          .th-content-footer-row{
            text-align: left;
            ul{
            &.th-menu-footer{
              display: block;
              padding-left: 0;
              text-align: left;
              li{
                margin-bottom: $margin-base;
              }
            }
          }
            input {
              width: 85%;
            }
          }
          section {
            margin-top: $margin-base;
            text-align: left;
            button{
              background-color: $black;
              border: 1px solid $white;
              padding: 0.30rem 1.5rem;
              font-size: $font-small;
              display: inline;
            }
          }
        }
    }
  }
  .list-unstyled{
    display: block;
  }
}


@media (min-width: 100px) and (max-width: 600px){
  .th-platform-header {
    width: 100%;
    margin-bottom: 23px;
    .th-title-platform{
      height: 100px;
    }
    .th-image-container {
      .th-image {
        img {
          padding: 0;
          width: 100%;
          box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .th-platform-content{
    width: 99%;
    padding: $remove-padding!important;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
    margin-bottom: 0!important;
    margin-top: 0!important;


    h3{
      &.th-h3-title{
        font-size: 2rem;
        padding-top: $remove-padding;
        margin-top: $remove-margin;
    }
    }
    .th-content-simply-list{
      margin-left: $remove-margin;
      width: 100%;
      .th-content-simply-list-title{
        margin-bottom: $remove-margin;
        margin-left: 15px;
        span{
          font-size: 1.375rem;
        }
      }
      ul{
        width: 85%;
        margin-top: $margin-base;
        font-size: 0.8rem;
        padding-left: $remove-padding;
        margin-left: 45px;
        li{
          margin-bottom: 0;
        }

      }
    }
    .th-content-icons{
      margin-left: 15%;
      margin-bottom: 20px;
    }
    &.th-platform-machine-learning-container{
      padding-top: $remove-padding;
      padding-bottom: $remove-padding;
      margin-top: $remove-margin;
      .th-content-simply-list{
        ul{
          li{
            margin-bottom: $remove-margin;
          }
        }
      }
      .th-img-machine-learning-container {
        margin: 10% 0 auto;
        width: 100%;
        img{
          top: 0;
        }
      }
    }
    &.th-platform-solid-credit-process-container{
      height: auto;
      padding-bottom: 20px;
      .th-solid-credit-process-image{
        width: 100%;
        margin-left: $remove-margin;
        img{
          margin: $margin-auto;
          width: 85%;

        }
      }
      .th-solid-credit-process-list{
        width: 100%;
        margin: $remove-margin $remove-margin 30px;
        position: relative;
        top: 20px;
        .th-content-simply-list-title{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 1.375rem;
          }
        }
        ul{
          padding: $remove-padding;
          margin-left: 30px;
          margin-top: 10px;
          width: 85%;
          li{
            margin-bottom: 10px;
            span{
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    &.th-platform-core-loan-backend-container{
      display: block;
      position: relative;
      top: 0;
      .th-content-simply-list{
        width: 100%;
        margin: $remove-margin;
        position: relative;
        top: 20px;
        .th-content-simply-list-title{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 1.375rem;
          }
        }
        ul{
          padding: $remove-padding;
          margin-left: 50px;
          margin-top: 10px;
          width: 85%;
          li{
            margin-bottom: 0;
            span{
              font-size: 0.8rem;
            }
          }
        }
      }
      .th-img-core-loan-container{
        width: 100%;
        margin-bottom: 40px;
        img{
          top:30px;
          width: $full-width;
        }
      }
    }
    &.th-platform-api-container{
      display: block;
      position: relative;
      top: 0;
      padding-bottom: $remove-padding;
      margin-bottom: 30px;
      .th-content-simply-list{
        width: 100%;
        margin: $remove-margin;
        position: relative;
        top: -360px;
        float: left;
        .th-content-simply-list-title{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 1.375rem;
          }
        }
        ul{
          padding: $remove-padding;
          margin-left: 50px;
          margin-top: 10px;
          width: 75%;
          li{
            margin-bottom: 0;
            span{
              font-size: 0.8rem;
            }
          }
        }
      }
      .th-img-api-container{
        width: 100%;
        float: right;
        position: relative;
        top: 180px;
        margin-bottom: 40px;
        img{
          top:30px;
          width: $full-width;
        }
      }
    }
    &.th-platform-collections-management-container{
      display: block;
      position: relative;
      top: 0;
      .th-content-simply-list{
        width: 100%;
        margin: $remove-margin;
        .th-content-simply-list-title{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 1.375rem;
          }
        }
        ul{
          padding: $remove-padding;
          margin-left: 50px;
          margin-top: 10px;
          width: 75%;
          li{
            margin-bottom: 0;
            span{
              font-size: 0.8rem;
            }
          }
        }
      }
      .th-img-collections-management-container{
        width: 100%;
        img{
          top:30px;
          margin-bottom: 50px;
          margin-left: 15px;
        }
      }
    }
    &.th-platform-marketing-tracking-container{
      display: block;
      position: relative;
      top: 0;
      .th-content-simply-list{
        width: 100%;
        margin: $remove-margin;
        position: relative;
        top: -420px;
        .th-content-simply-list-title{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 1.375rem;
          }
        }
        ul{
          padding: $remove-padding;
          margin-left: 50px;
          margin-top: 10px;
          width: 75%;
          li{
            margin-bottom: 0;
            span{
              font-size: 0.8rem;
            }
          }
        }
      }
      .th-img-marketing-tracking-container{
        width: 100%;
        margin-left: 0;
        position: relative;
        top: 220px;
        img{
          top:30px;
          margin-bottom: 50px;
        }
      }
    }
  }
  .th-company-content{
    &.th-company-footer{
      .th-footer-container{
        padding-top: 10px;
        display: block;
        .th-footer-column{
          width: 90%;
          margin: $margin-auto;
          .th-menu-footer{
            display: flex!important;
            li{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}


