.th-text-slider{
  text-align: center;
  margin-top: 60px;
  position: relative;
  height: 50px;
  padding-top: $padding-xlarge + 10;
  .th-text-slider-1,
  .th-text-slider-2,
  .th-text-slider-3 {
    position: absolute;
    display: block;
    width: 100%;

    font-size: 2em;
    color: $white;



    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .th-text-slider-1{
    animation-name: th-text-slider-anim-1;
  }
  .th-text-slider-2{
    animation-name: th-text-slider-anim-2;
  }
  .th-text-slider-3{
    animation-name: th-text-slider-anim-3;
  }

  @keyframes th-text-slider-anim-1 {
    0%, 8.3% { left: -100%; opacity: 0; }
    8.3%,25% { left: 0; opacity: 1; }
    33.33%, 100% { right: 0; opacity: 0; }
  }
  @keyframes th-text-slider-anim-2 {
    0%, 33.33% { left: -100%; opacity: 0; }
    41.63%, 58.29% { left: 0; opacity: 1; }
    66.66%, 100% { right: 0; opacity: 0; }
  }
  @keyframes th-text-slider-anim-3 {
    0%, 66.66% { left: -100%; opacity: 0; }
    74.96%, 91.62% { left: 0; opacity: 1; }
    100% { right: 0; opacity: 0; }
  }

}