
@import "../core/scss/cr-variables";
@import "theme-variables";
@import "theme-base";



@import "theme-home";
@import "theme-home-text-slider";

@import "theme-section-platform";
@import "theme-section-solutions";
@import "theme-section-company";
