body {
  line-height: 22px;
  font-family: $th-font-family-base!important;
  h1,h2,h3,h4,span,button,a,p,{
    font-family: $th-font-family-base!important;
  }
}

a{
  color: $gray-theme !important;
  &:hover{
    color: $orange !important;
    text-decoration: none !important;
  }
}


.th-dropdown-menu{
  border-radius: 0 0 5px 5px;
  border-top: 0 none;
  top: 38px;
  box-shadow: 0 3px 5px 0 rgba(143, 143, 143, 0.2);
  .dropdown-item{
    width: auto;
    padding-bottom: 6px;
    padding-top: 6px;
  }
}
@media (min-width: 100px) and (max-width: 600px){
  .th-company-content{
    &.th-company-footer{
      .th-footer-container{
        padding-top: 10px;
        display: block;
        .th-footer-column{
          width: 90%;
          margin: $margin-auto;
          .th-menu-footer{
            display: flex!important;
            li{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

