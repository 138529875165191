body {
  background-color: $white;
}

.th-company-header {
  width: 88%;
  font-family: $font-family-light;
  margin: $margin-auto;
  background-color: $primary-brand-theme;

  &.th-contact-header {
    h1 {
      color: $white;
      text-align: center;
    }

    h2 {
      margin-top: 0;
      color: $gray-text;
    }

    h3 {
      text-align: center;
      margin-right: 3%;
      color: $gray-text;
    }

    address {
      font-style: normal;
      color: $gray-text;

      i {
        color: $gray-text !important;
      }
    }

    .social-contact {
      i {
        color: $gray-desactive !important;
        font-size: 30px !important;

        &:hover {
          color: $primary-brand-theme !important;
        }
      }
    }

    padding-top: 100px;

    .cr-contact-form-container {
      margin-top: 20px;
      font-family: $font-family-light;

      .office-location {
        margin-bottom: 10px;
      }
    }

    .contact-info-section {
      text-align: left;

      i {
        color: #2e6089;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .th-title-company {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
    padding-top: $padding-xlarge + 10;

    ul {
      display: block;
      margin: 0 auto;
      padding: $remove-padding;

      li {
        span {
          color: $white;
        }
      }
    }

    h1 {
      display: block;
      width: 100%;
      font-size: 2em;
      color: $white;
    }

    span {
      color: $white;
    }

    .th-content-icons {
      margin: $margin-auto;
      margin-bottom: $margin-base;

      .th-icon-container-money {
        border-radius: 10px;
        background-color: $white;
        margin: $margin-auto;
        width: 70px;
        height: 70px;

        i {
          font-size: $font-large + 10;
          color: #2e6089;
          top: 22%;
          position: relative;

          &.icon-cr-car-monopoly {
            font-size: 60px;
            top: 10%;
          }
        }
      }
    }

    .th-content-logo {
      img {
        height: 60px;
        width: auto;
      }
    }

    .th-content-photo-team {
      img {
        height: auto;
        width: 85%;
      }
    }
  }

  .th-image-container {
    background-color: $white;
    position: relative;

    .th-half-image {
      background-color: $primary-brand-theme;
      position: absolute;
      height: 50%;
      width: $full-width;
      box-shadow: 0 10px 19px rgba(0, 0, 0, 0.07);
    }

    .th-image {
      margin: $margin-auto;
      position: relative;
      top: 0;
      text-align: center;

      &.th-contact-container {
        background-color: #fff;
        padding-left: 5%;
        padding-right: 5%;
        width: 73%;
        padding-top: 2%;

        iframe {
          width: 100%;
          height: 400px;
        }
      }

      img {
        width: 83%;
        padding: 2px;
        box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.5);
        background-color: $white;
      }
    }

    .th-content {
      margin: $margin-auto $margin-base;
      position: relative;
      padding: $padding-large * 2 0 $padding-large;
      top: 0;
      text-align: center;
      box-shadow: 0 2px 5px 0 rgba(143, 143, 143, 0.5);
      background-color: $white;
      width: 85%;

      .th-content-row {
        margin-bottom: 5%;

        h3 {
          margin: $remove-margin;
        }

        &.th-car-customization {
          margin-bottom: 2%;
        }

        &.th-photos-team {
          text-align: center;
          width: 100%;

          .th-all-content-team-info {
            position: relative;
            display: inline-block;

            .th-icon-linkedin-container {
              position: absolute;
              right: 35px;
              z-index: 89;
              background-color: #0077b5;
              border-radius: 50%;
              width: 28px;
              height: 28px;
              opacity: .6;
              top: 5px;

              &:hover {
                cursor: pointer;
                opacity: 1;
              }

              i {
                color: $white;
                font-size: 19px;
                margin: $margin-auto;
                top: 3px;
                position: relative;
              }
            }

            .th-content-team-photo {
              width: 180px;
              height: 180px;
              overflow: hidden;
              border-radius: 50%;
              // margin-right: 10%;
              position: relative;
              //border: 1px solid rgba(0,119,181,1);
              box-shadow: 0 0 5px 0 rgba(143, 143, 143, 0.5);

              img {
                width: 100%;
                height: auto;
              }
            }

            .th-content-team-info {
              ul {
                display: block;
                margin-top: 20px;
                padding: 0;

                li {
                  margin-bottom: 5px;

                  &:nth-child(1) {
                    font-weight: $font-weight-bold;
                  }
                }
              }
            }
          }
        }

        &.th-company-about {
          .th-content-simply-list {
            width: 100%;
            margin-left: $remove-margin;

            .th-content-simply-list-title {
              text-align: center;
            }

            ul {
              padding-left: $remove-padding;
              list-style: none;
              margin: $margin-auto;

              li {
                text-align: center;
              }
            }
          }
        }

        &.th-axiomas {
          display: flex;
          margin-top: 5%;

          .th-text-big {
            span {
              font-size: 20px !important;
            }
          }

          .th-content-simply-list {
            .th-row-simply-list {
              margin-bottom: 60px;
            }
          }
        }

        .th-content-simply-list {
          width: 60%;
          margin-left: 10%;
          margin-right: 0;
          position: relative;

          .th-content-simply-list-title {
            text-align: left;
            margin-bottom: $margin-large;

            &.th-text-big {
              span {
                font-size: $font-large;
              }
            }

            span {
              font-weight: $font-weight-bold;
            }
          }

          ul {
            display: block;
            list-style: inherit;
            width: 90%;

            li {
              text-align: left;
              margin-bottom: $margin-small * 2;
            }
          }
        }

        .th-img-company-container {
          img {
            height: 220px;
            width: auto;
          }

          &.th-img-axiomas {
            img {
              width: 100%;
              height: auto;
              height: auto;
              position: relative;
              top: -60px;
            }
          }
        }

        .th-img-flexibility-container {
          img {
            height: 300px;
            width: auto;
          }
        }

        .th-img-volumen-processing-container {
          img {
            height: 250px;
            width: auto;
          }
        }

        .th-img-process-customatization-container {
          img {
            position: relative;
            top: -10px;
            height: 350px;
            width: auto;
          }
        }
      }

      .th-content-team-row {
        display: flex;
        margin: 0 auto;
        text-align: center;
        width: 75%;

        .th-worker-team-container {
          background-color: green;

          .th-worker-team-photo-container {
            .th-linkedin-icon-container {
              i {
              }
            }

            .th-photo-worker {
              img {
                width: 150px;
                height: auto;
              }
            }
          }

          .th-worker-team-info-container {
            ul {
              li {
              }
            }
          }
        }
      }
    }
  }
}

.th-company-content {
  position: relative;
  text-align: center;
  padding: $padding-large;
  width: 80%;
  margin: $margin-auto;
  margin-top: 3%;
  background-color: $white;

  h3 {
    color: #404040;
    display: block;

    &.th-h3-title {
      font-weight: bold;
      color: #404040;
      margin: $remove-margin !important;
      font-size: $font-xlarge;
    }

    &.th-title-our-process {
      margin-bottom: 40px;
    }
  }

  ul {
    &.th-our-procees {
      padding: $remove-padding;
      text-align: center;
      width: 100%;

      li {
        width: 19%;
        background-color: #859a9a;
        margin-right: 2px;
        margin: $margin-auto;

        .th-step-process-container {
          position: relative;

          .th-icon-section-process-container {
            background-color: $white;

            .th-icon-process-container {
              width: 50px;
              height: 50px;
              background-color: #22c9f9;
              border-radius: 50%;
              margin: 0 auto;
              position: relative;
              top: 25px;

              i {
                color: $white;
                font-size: 32px;
                position: relative;
                top: 8px;
              }
            }
          }

          .th-job-process-description-container {
            padding-top: $padding-base * 4;
            padding-bottom: $padding-base * 2;
            padding-left: $padding-base * 3;
            padding-right: $padding-base * 3;

            span {
              color: white;
            }
          }
        }
      }
    }
  }

  &.th-case-study {
    h3 {
      margin-bottom: $margin-base * 2;
    }

    .th-company-large-text {
      padding-top: 2%;
      width: 75%;
      display: block;
      margin: $margin-auto;
      margin-bottom: $margin-base * 3;
    }

    img {
      width: 250px;
      height: auto;
    }
  }

  &.th-are-you-a-fit {
    background-color: #f9f9f9;
    padding: $remove-padding;
    width: 100%;
    max-width: 1200px;

    img {
      width: 100%;
      height: auto;
      margin: $margin-auto;
    }

    h3 {
      padding-top: 20px;
      margin: 0;
    }
  }

  &.th-company-footer {
    width: 100%;
    padding-left: $remove-padding;
    padding-right: $remove-padding;
  }

  .th-content-simply-list {
    width: 50%;
    margin-left: 15%;
    position: relative;

    .th-content-simply-list-title {
      text-align: left;
      margin-bottom: $margin-large;

      &.th-text-big {
        span {
          font-size: $font-large;
        }
      }

      span {
        font-weight: $font-weight-bold;
      }
    }

    ul {
      display: block;
      list-style: inherit;

      li {
        text-align: left;
        margin-bottom: $margin-small;
      }
    }
  }

  .th-content-icons {
    position: relative;
    top: 15px;

    ul {
      margin-top: $margin-large;
      margin-bottom: $margin-large;
      padding-left: $remove-padding;

      li {
        margin-right: $margin-base;

        .th-icon-container-technology-app {
          border-radius: 10px;
          background-color: $black;
          margin: $margin-auto;
          width: 70px;
          height: 70px;

          i {
            font-size: $font-large + 10;
            color: $white;
            vertical-align: middle;
            top: 22%;
            position: relative;

            &.icon-cr-clients-infinity {
              font-size: $font-xlarge + 20;
              position: relative;
              top: 9px;
            }
          }
        }
      }
    }
  }

  &.th-company-core-loan-backend-container {
    display: flex;
    top: 100px;

    .th-content-simply-list {
      width: 38%;
    }

    .th-img-core-loan-container {
      width: 40%;

      img {
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;
      }
    }
  }

  &.th-company-collections-management-container {
    display: flex;

    .th-content-simply-list {
      width: 55%;
    }

    .th-img-collections-management-container {
      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }

  &.th-company-risk-management-container {
    display: flex;
    background-color: #2e6089;
    padding-top: 5%;
    padding-bottom: 3%;

    .th-content-simply-list {
      margin-left: 5%;
      margin-right: 1%;
      width: 55%;

      .th-content-simply-list-title {
        span {
          color: $white;
        }
      }

      ul {
        display: block;
        list-style: none;
        width: 90%;

        li {
          text-align: left;
          margin-bottom: $margin-large;

          span {
            color: $white;
          }

          &::before {
            content: "• ";
            color: $white;
          }
        }
      }
    }

    .th-img-risk-management-container {
      display: flex;
      position: relative;
      top: 20px;

      img {
        height: auto;
        left: -20px;
        position: relative;
        top: -34px;
        width: 70%;
      }
    }
  }

  &.th-company-api-container {
    display: flex;
    top: 100px;

    .th-content-simply-list {
      margin-left: 5%;

      ul {
        width: 85%;
      }
    }

    .th-img-api-container {
      width: 40%;
      margin-left: 15%;

      img {
        width: 100%;
        height: auto;
        position: relative;
        top: -115px;
      }
    }
  }

  &.th-company-marketing-tracking-container {
    display: flex;
    top: 50px;

    .th-content-simply-list {
      margin-left: 0;

      ul {
        width: 85%;
      }
    }

    .th-img-marketing-tracking-container {
      width: 30%;
      margin-left: 15%;

      img {
        width: 90%;
        height: auto;
        position: relative;
        top: -115px;
      }
    }
  }

  &.th-company-machine-learning-container {
    margin-top: $margin-base * 3;
    padding-top: $padding-large * 3;
    padding-bottom: $padding-large * 2;
    background-color: $gray-light-theme;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.06);

    .th-content-simply-list {
      .th-content-simply-list-title {
        font-size: $font-xlarge;
        position: relative;
        top: 20px;
      }

      ul {
        position: relative;
        top: 20px;

        li {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.th-company-solid-credit-process-container {
    background-color: $primary-brand-theme;
    margin: 0 auto;
    padding-top: $remove-padding;
    padding-bottom: $remove-padding;
    height: 410px;

    .th-solid-credit-process-image {
      width: 45%;
      margin: 0 auto;
      right: 0;

      i {
        margin-left: 30%;
        font-size: 400px;
        color: $white;
      }
    }

    .th-solid-credit-process-list {
      width: 40%;
      margin-left: 0%;
      position: relative;
      top: 80px;

      .th-content-simply-list-title {
        text-align: left;
        margin-bottom: $margin-large;

        span {
          font-weight: $font-weight-bold;
          color: $white;
          font-size: $font-xlarge;
        }
      }

      ul {
        display: block;
        list-style: none;

        li {
          text-align: left;
          margin-bottom: $margin-large;

          span {
            color: $white;
          }

          &::before {
            content: "• ";
            color: $white;
          }
        }
      }
    }
  }

  .th-content-machine-learning-container {
    margin-left: 5%;

    img {
      height: 240px;
      width: auto;
    }
  }

  .th-footer-container {
    background-color: $black;
    color: $white;
    display: flex;
    padding-bottom: $padding-large;

    .th-footer-column {
      width: 30%;
      margin-right: 3%;
      padding-left: 1%;
      padding-right: 1%;

      .th-footer-row {
        .th-title-footer-row {
          text-align: left;
        }

        hr {
          border-color: rgba(255, 255, 255, .3);
        }

        .th-content-footer-row {
          text-align: left;

          ul {
            &.th-menu-footer {
              display: block;
              padding-left: 0;
              text-align: left;

              li {
                margin-bottom: $margin-base;
              }
            }
          }

          input {
            width: 85%;
          }
        }

        section {
          margin-top: $margin-base;
          text-align: left;

          button {
            background-color: $black;
            border: 1px solid $white;
            padding: 0.30rem 1.5rem;
            font-size: $font-small;
            display: inline;
          }
        }
      }
    }
  }

  .list-unstyled {
    display: block;
  }
}


@media (min-width: 100px) and (max-width: 600px) {
  .th-company-header {
    width: 100%;

    .th-image-container {
      .th-content {
        width: 100%;
        padding-bottom: 20px;

        .th-content-row {
          &.th-photos-team {
            .th-all-content-team-info {
              width: 40%;
              margin-top: 5px;

              .th-content-team-photo {
                margin: 0 auto;
                height: 140px;
                width: 140px;
              }

              .th-icon-linkedin-container {
                right: 18px;
                height: 25px;
                width: 25px;
              }

              .th-content-team-info {
                ul {
                  li {
                    font-size: 0.8rem;
                  }
                }
              }
            }
          }

          &.th-axiomas {
            width: 100%;
            display: block;

            .th-content-simply-list {
              width: 100%;
              margin-left: $remove-margin;
              margin-bottom: 60px;

              .th-row-simply-list {
                margin-bottom: $remove-margin;
                width: 90%;
                margin-left: 0;
                margin-bottom: 15px;

                .th-content-simply-list-title {
                  &.th-text-big {
                    margin-left: 5%;
                    margin-bottom: 5px;
                  }
                }

                ul {
                  padding-left: $remove-padding;
                  margin-left: 15%;
                }
              }
            }
          }
        }
      }

      .th-image {
        img {
          width: 100%;
          padding: $remove-padding;
        }
      }
    }
  }

  .th-company-content {
    &.th-our-process {
      width: 100%;
      padding: $remove-padding;

      h3 {
        &.th-title-our-process {
          margin-bottom: $margin-base;
        }
      }

      ul {
        &.th-our-procees {
          display: block;

          li {
            width: 100%;
            border-bottom: 1px solid $gray-desactive;

            .th-step-process-container {
              padding: 15px;

              .th-icon-section-process-container {
                background-color: #859a9a;

                .th-icon-process-container {
                  top: 0;
                  position: relative;
                  margin-bottom: 10px;
                }
              }

              .th-job-process-description-container {
                padding: $remove-padding;
              }
            }
          }
        }
      }
    }

    &.th-are-you-a-fit {
      margin-top: $remove-margin;
    }
  }
}
